<template>
  <div class="home-layout">
    <v-card class="head-panel">
      <v-toolbar
        dense
        height="44"
        class="navigation"
        elevation="0"
      >
        <v-img
          :src=layouts.data.portfolioIcon
          max-height="32"
          max-width="90"
        />
        <v-spacer></v-spacer>
        <v-toolbar-items class="action-container">
<!--          <wap-call-phone-icon></wap-call-phone-icon>-->

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18">mdi-text</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in actionMenu"
                :key="index"
                @click="listActions(item.title)"
              >
                <v-list-item-title link >{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

        </v-toolbar-items>
      </v-toolbar>
    </v-card>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { Tool, Cache } from '@/assets/js/util'
// import WapCallPhoneIcon from '@/components/base/WapCallPhoneIcon'

export default {
  name: 'HomeLayout',
  components: { },
  data: () => ({
    assist: {
      tool: new Tool(),
      cache: new Cache()
    },
    actionMenu: [
      { title: 'Change Password' },
      { title: 'Sign Out' }
    ],
    layouts: {
      data: {
        portfolioIcon: ''
      }
    }
  }),
  watch: {
    'store.getters.getPortfolioJson': {
      handler (val) {
        if (this.assist.tool.isNotEmpty(val)) {
          this.layouts.data.portfolioIcon = val.logoUrl
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    if (this.assist.tool.isNotEmpty(this.$store.getters.getPortfolioJson)) {
      this.layouts.data.portfolioIcon = this.$store.getters.getPortfolioJson.logoUrl
    }
    this.disableBtn()
  },
  methods: {
    disableBtn () {
    },
    listActions (title) {
      if (title === 'Change Password') {
        this.$router.push('/change-password')
      } else {
        this.assist.cache.clear()
        this.$router.push('/sign-in')
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.home-layout
  & .field-label
    opacity: 0.6

  & .field-value
    opacity: 0.8

  & .head-panel
    position: relative
    border-radius: 0 0 16px 16px
    background-color: var(--theme_primary)
    height: 120px

    & .navigation
      background-color: transparent

      & .action-container
        & .v-btn
          color: #F5F5F5

      & .field-label
        font-size: 12px

      & .field-value
        font-size: 24px

</style>

<style lang="sass">
.home-layout
  & .main-content
    padding: 16px 16px
</style>
